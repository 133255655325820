@import '/src/scss/animations.module.scss';
@import '/src/scss/variables.module.scss';
@import '/src/scss/mixins.module.scss';

.Header {
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $quadPixelUnit;
  margin: $doublePixelUnit 0 $quadPixelUnit;
  width: 100%;
  position: relative;
  opacity: 0;
  @include fade-in-animation(4s, 0.5s, 1);

  // asdasd
  &IncreaseZ {
    z-index: 2;
  }

  &Menu {
    &Trigger {
      position: absolute;
      top: 0;
      right: $doublePixelUnit;
      &Icon {
        position: absolute;
        top: 0;
        right: -8px;
        @include getIconStyle(1, 500);
        &:hover {
          @include getIconStyle(1, 700);
        }
      }
    }
    &Item {
      &Icon {
        @include getIconStyle(0, 600);
      }
      &Hidden {
        display: none;
      }
    }
  }

  &NavItems {
    display: flex;
    gap: $quadPixelUnit;
  }
}
