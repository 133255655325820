@import '/src/scss/animations.module.scss';
@import '/src/scss/variables.module.scss';
@import '/src/scss/mixins.module.scss';

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &Scene {
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 1;
    overflow: auto;
    
    &Display {
      display: flex;
      flex: 1;
      margin: 0 $quadPixelUnit $quadPixelUnit;
      @include getFillAvailableWidth();
    }
  }
}

@media only screen and (max-width: 375px) {
  .AppSceneDisplay {
    margin: 0 $doublePixelUnit $doublePixelUnit;
  }
}

@media only screen and (max-width: 320px) {
  .AppSceneDisplay {
    margin: 0 $pixelUnit $pixelUnit;
  }
}

.Modals {
  position: absolute;
  top: 0;
  left: 0;
  // z-index: 10000;
  width: 100%;
  height: 100%;
}