@import '/src/scss/mixins.module.scss';
@import '/src/scss/variables.module.scss';

.HomeDisplay {
  @include sceneBaseStyle();
  &Header {
    @include sceneHeaderBaseStyle();
    .HeaderOverlineText {
      display: flex;
      gap: $pixelUnit;
      align-items: center;
    }
    a {
      display: inherit;
      align-items: inherit;
      gap: inherit;
    }
  }
}

@media only screen and (max-width: 375px) {
  .HomeDisplayHeader {
    .HeaderOverlineText {
      gap: calc($pixelUnit / 2);
      div {
        font-size: 0.75rem;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .HomeDisplayHeader {
    .HeaderOverlineText {
      gap: calc($pixelUnit / 2);
      div {
        font-size: 1rem;
      }
    }
  }
}