:root {
  // --mdc-theme-background: #203647;
  --mdc-theme-background: #12232e;
  // --mdc-theme-primary: #12232e;
  --mdc-theme-primary: #b2e3ff;
  --mdc-theme-secondary: #007cc7;
  --mdc-theme-surface: #f4fbff;
  --mdc-theme-error: #b00020;
  --mdc-theme-success: #1e8800;
  --mdc-theme-on-primary: #e7f8ff;
  --mdc-theme-on-secondary: #4da8da;
  --mdc-theme-on-surface: #54c1ff;
  --mdc-theme-on-error: #f4fbff;
  --mdc-theme-button-on-background: #0c4263;
  --mdc-theme-collapsable-row-background: #a8dfffbf;
  --mdc-theme-text-primary-on-background: #c5eeff;
  --mdc-theme-text-primary-on-background-rgb: rgb(231, 248, 255);
  --mdc-theme-text-secondary-on-background: #12232e;
  --mdc-theme-text-hint-on-background: #9eaab3;
  --mdc-theme-text-disabled-on-background: rgba(142, 210, 246, 0.5);
  --mdc-theme-text-icon-on-background: #398fc5;
  --mdc-theme-text-primary-on-light: rgba(12, 66, 99, 0.75);
  --mdc-theme-text-secondary-on-light: #2c5873;
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: #f4fbff;
  --mdc-theme-text-secondary-on-dark: rgba(0, 74, 120, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: #f4fbff;
  --mdc-theme-card-background: #0278bf;
  --mdc-theme-text-error-on-primary: #e197a4;
  --mdc-checkbox-unchecked-color: #4da8da;
  --mdc-typography-font-family: 'Barlow Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --highlight-color: null;
}

body {
  height: 100vh;
  font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 48;
  margin: 0;
  font-family: var(--mdc-typography-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--mdc-theme-text-primary-on-background);
  background: #12232e;
  background: url('/images/bg.webp') no-repeat center center fixed;
  background-size: cover;
}

div[class*='mdc-elevation--z2'],
a[class*='mdc-elevation--z2'] {
  box-shadow: 0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(234 234 234 / 14%),
    0px 1px 5px 0px rgb(255 255 255 / 72%);
}
div[class*='mdc-elevation--z3'],
a[class*='mdc-elevation--z3'] {
  box-shadow: 0px 3px 3px -2px rgb(255 255 255 / 20%), 0px 3px 4px 0px rgb(234 234 234 / 14%),
    0px 1px 8px 0px rgb(255 255 255 / 72%);
}
div[class*='mdc-elevation--z5'],
a[class*='mdc-elevation--z5'] {
  box-shadow: 0px 3px 5px -1px rgb(255 255 255 / 20%), 0px 5px 8px 0px rgb(234 234 234 / 14%),
    0px 1px 14px 0px rgb(255 255 255 / 12%);
}
div[class*='mdc-elevation--z7'],
a[class*='mdc-elevation--z7'] {
  box-shadow: 0px 4px 5px -2px rgb(255 255 255 / 20%), 0px 7px 10px 1px rgb(234 234 234 / 14%),
    0px 2px 16px 1px rgb(255 255 255 / 12%), 1px -2px 11px 2px rgb(159 208 227 / 70%);
}
div[class*='mdc-elevation--z12'],
a[class*='mdc-elevation--z12'] {
  box-shadow: 0px 7px 8px -4px rgb(255 255 255 / 20%), 0px 12px 17px 2px rgb(234 234 234 / 14%),
    0px 5px 22px 4px rgb(255 255 255 / 12%);
}
div[class*='mdc-elevation--z18'],
a[class*='mdc-elevation--z18'] {
  box-shadow: 0px 9px 11px -5px rgba(153, 153, 153, 0.2), 0px 18px 28px 2px rgba(137, 136, 136, 0.14),
    0px 7px 34px 6px rgba(91, 90, 90, 0.12);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: calc(var(--vh, 1vh) * 100);
  height: 100%;
  width: 100%;
  max-width: calc(var(--vw, 1vw) * 100);
  background: rgba(0, 29, 47, 0.8);
  &::before {
    content: ' ';
    background: url('/images/eleventheye-logo.webp') no-repeat center center fixed;
    background-size: contain;
    opacity: 0.03;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

a {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: transparent;
}

/// margin-left
*[class*='m-l-1x'] {
  margin-left: 4px;
}
*[class*='m-l-2x'] {
  margin-left: 8px;
}
*[class*='m-l-4x'],
*[class*='m-l-4x'] {
  margin-left: 16px;
}

*[class*='fl-col'] {
  display: flex;
  flex-direction: column;
  flex: 1;
}

*[class*='fl-pad-4x'] {
  padding: 16px;
}

*[class*='fl-border-top'] {
  border-top: thin solid var(--mdc-theme-secondary);
}

*[class*='fl-row'] {
  display: flex;
  flex-direction: row;
  flex: 1;
}
*[class*='fl-row-jc'] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
*[class*='fl-row-wrap'] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

*[class*='fl-gap-1x'] {
  gap: 4px;
}
*[class*='fl-gap-2x'] {
  gap: 8px;
}
*[class*='fl-gap-4x'] {
  gap: 16px;
}

/** 

Dark Blue: #12232E

Lighter Blue: #007CC7

Lightest Blue: #4DA8DA

Shadow of Dark Blue: #203647

Shadow of Light Blue: #EEFBFB

*/
