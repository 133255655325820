@import '/src/scss/animations.module.scss';

$pixelUnit: 4px;
$doublePixelUnit: 8px;
$triplePixelUnit: 12px;
$quadPixelUnit: 16px;
$quintPixelUnit: 20px;
$sextPixelUnit: 24px;
$septPixelUnit: 28px;
$octPixelUnit: 32px;

$headerButtonSize: 48px;
$editButtonSize: 48px;
$eleventheyeLogoSize: 48px;

$portfolioNameFontSize: 24px;
$portfolioTitleFontSize: 14px;

$fieldBg: rgb(0 0 0 / 30%);
$logoBg: rgba(255, 255, 255, 0.3);
$tagBadgeBorderColor: rgb(77, 168, 218);
$skillBadgeBorderColor: rgb(0, 124, 199);

$MAX_WIDTH_DESKTOP: 700px;