@import '/src/scss/mixins.module.scss';
@import '/src/scss/variables.module.scss';

.HeaderIcon {
  &ButtonElevation {
    border: thin solid rgba(0, 124, 199, 0.1);
    background-color: $fieldBg;
    @include getSquareWidthHeight($headerButtonSize);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    i {
      color: var(--mdc-theme-secondary);
      @include getIconStyle(0, 300);
    }
    &:hover {
      i {
        @include getIconStyle(0, 500);
      }
    }
  }
  &ButtonElevation[class*='active'] {
    border-color: rgba(0, 124, 199, 0.35);
    i {
      color: var(--mdc-theme-surface);
      @include getIconStyle(0, 500);
    }
  }
}
