@import '/src/scss/mixins.module.scss';
@import '/src/scss/variables.module.scss';

.CreateUserDialog {
  &Content {
    @include getFlexDisplay(column);
    gap: $quadPixelUnit;
    min-width: 250px;
    padding: $quadPixelUnit;
  }
  &Error {
    @include getFlexDisplay(row);
    height: $septPixelUnit;
    color: var(--mdc-theme-text-error-on-primary);
  }
  &ActionButtons {
    @include getFlexDisplay(row, unset, center, space-between);
    flex: 1;
  }
}