@import '/src/scss/animations.module.scss';
@import '/src/scss/mixins.module.scss';
@import '/src/scss/variables.module.scss';

div.QuoteDisplay {
  @include getFlexDisplay(row);
  flex: 1;
}

figure.QuoteFigure {
  @include getVerticalLine(thick);
  background-color: rgb(1 1 1 / 20%);
  border-radius: 0 $pixelUnit $pixelUnit 0;
  padding: 1px;
  position: relative;
  blockquote.QuoteBlockquote {
    margin-inline-start: $quadPixelUnit;
    margin-inline-end: $quadPixelUnit;
    opacity: 0;
    @include fade-in-animation(3s, 0.5s, 1);
  }
}

@media only screen and (max-width: 320px) {
  figure.QuoteFigure {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: $quadPixelUnit;
    margin-inline-end: $quadPixelUnit;
    blockquote.QuoteBlockquote {
      margin-inline-start: $doublePixelUnit;
      margin-inline-end: $doublePixelUnit;
    }
  }
}
