@import '/src/scss/mixins.module.scss';
@import '/src/scss/variables.module.scss';

.BaseDialog {
  @include getBackgroundColor(#024970, 0.85);

  div[class*='mdc-dialog__surface'] {
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      background: url('/images/bg.webp') no-repeat center center fixed;
      background-size: cover;
      @include getSquareWidthHeight(100%);
    }
    background: rgba(0, 29, 47, 0.8);
    @include getBorder(rgb(5, 89, 134), 0.9, solid);
    box-shadow: 0px 11px 15px -7px rgb(255 255 255 / 20%), 0px 24px 38px 3px rgb(234 234 234 / 14%),
      0px 9px 46px 8px rgb(255 255 255 / 12%);
  }
  h2[class*="mdc-dialog__title"] {
    text-align: center;
  }
}
