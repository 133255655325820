.ErrorBoundary {
  text-align: center;

  &Countdown {
    font-weight: 600;
    font-size: x-large;
  }

  &ErrorText {
    font-size: 64px;
  }

  &GreenerPastures {
    color: rgb(20, 229, 20);
    font-size: larger;
  }
}
