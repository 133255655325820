@import '/src/scss/mixins.module.scss';
@import '/src/scss/variables.module.scss';

@mixin snackbarColors($bgColor, $btnColor) {
  div[class*='mdc-snackbar__surface'] {
    background-color: var($bgColor);
    button[class*='mdc-snackbar__action']:not(:disabled) {
      color: var($btnColor);
    }
  }
}

.NSDisplay {
  @include getFlexDisplay(row, unset, center);
  font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48;

  &--info {
    @include snackbarColors(--mdc-theme-on-secondary, --mdc-theme-primary);
  }
  &--success {
    @include snackbarColors(--mdc-theme-success, --mdc-theme-primary);
  }
  &--error {
    @include snackbarColors(--mdc-theme-error, --mdc-theme-primary);
  }
}
