@import '/src/scss/animations.module.scss';

.Logo {
  &Display {
    position: relative;
    &TextEye {
      font-weight: 100;
    }
  }
}

@media only screen and (min-width: 425px) {
  .Logo {
    &Display {
      @include logo-flicker();

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        filter: blur(1em);
        transform: translateY(50%) rotateX(182deg) scale(1, 0.29);
        background: var(--mdc-theme-text-secondary-on-dark);
        pointer-events: none;
        @include logo-light-up();

        &Text {
          opacity: 0;
        }
      }

      &Text {
        opacity: 0;
        @include logo-text-appear();
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .LogoDisplayText {
    line-height: 1.45rem;
    font-size: 1.45rem;
  }
}

@media only screen and (max-width: 375px) {
  .LogoDisplayText {
    display: none;
    line-height: 1.85rem;
    font-size: 1.85rem;
  }
}
