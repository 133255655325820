@import '/src/scss/variables.module.scss';
@import '/src/scss/animations.module.scss';

%robotBaseAnimationProps {
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

%flexRow {
  display: flex;
  flex-direction: row;
}

%flexColumn {
  display: flex;
  flex-direction: column;
}

@mixin getImageSizeProperties($size) {
  max-width: $size;
  min-width: $size;
  width: $size;
  height: $size;
  max-height: $size;
  min-height: $size;
}

@mixin getIconStyle($filled, $weight) {
  font-variation-settings: 'FILL' $filled, 'wght' $weight, 'GRAD' 0, 'opsz' 32;
}

@mixin getCollapsableCategoryColor() {
  background-color: var(--mdc-theme-button-on-background);
  color: var(--mdc-theme-secondary);
}

/// Fades in element animation
///
/// @param {string} $direction row / column
/// @param {string} $wrap flex-wrap, defaults to unset
/// @param {string} $align align-items, defaults to unset
/// @param {string} $justify justify-content, defaults to unset
///   Number of times to run the animation
@mixin getFlexDisplay($direction, $wrap: unset, $align: unset, $justify: unset) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  align-items: $align;
  justify-content: $justify;
}

@mixin getNameFontCls() {
  &Name {
    font-size: $portfolioNameFontSize;
    width: max-content;
  }
}
@mixin getProfessionFontCls() {
  &Profession {
    font-size: $portfolioTitleFontSize;
    letter-spacing: 0.48em;
    line-height: $quadPixelUnit;
  }
}

@mixin getFillAvailableWidth() {
  width: -webkit-fill-available;
  width: -moz-available;
}

@mixin getVerticalLine($lineWidth: thin, $lineColor: #007cc7) {
  border-left: $lineWidth solid $lineColor;
}

@mixin getBorder($rgbColor, $alpha, $lineStyle) {
  border: thin $lineStyle rgba($rgbColor, $alpha);
}

@mixin getSquareWidthHeight($value) {
  width: $value;
  height: $value;
}

@mixin getColor($colorName, $alpha) {
  color: transparentize($colorName, 1 - $alpha);
}

@mixin getBackgroundColor($colorName, $alpha) {
  background-color: transparentize($colorName, 1 - $alpha) !important;
}

@mixin sceneBaseStyle() {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: $doublePixelUnit;
}

@mixin sceneHeaderBaseStyle() {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0;
  @include fade-in-animation(1s, 0.5s, 1);
}

@mixin sceneBodyBaseStyle() {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $doublePixelUnit;
  opacity: 0;
  @include fade-in-animation(1s, 0.7s, 1);
}
