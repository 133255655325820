@import '/src/scss/mixins.module.scss';
@import '/src/scss/variables.module.scss';

.MainDisplay {
  &Body {
    @include sceneBodyBaseStyle();
  }

  &QandA {
    text-align: start;
    display: flex;
    flex-direction: column;
    padding: 0 $quadPixelUnit;
    gap: $quadPixelUnit;
    max-width: 600px;
  }
  &Section {
    opacity: 1;
  }

}
