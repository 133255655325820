@import '/src/scss/mixins.module.scss';
@import '/src/scss/variables.module.scss';

.BaseInputField {
  textarea,
  input {
    caret-color: var(--mdc-theme-secondary) !important;
    // -webkit-text-fill-color: var(--mdc-theme-text-primary-on-background) !important;
    color: var(--mdc-theme-primary) !important;
    &::-webkit-calendar-picker-indicator {
      filter: invert(49%) sepia(78%) saturate(1872%) hue-rotate(182deg) brightness(90%) contrast(89%);
      // filter: invert(1);
    }
    
  }
  input::selection {
    background: var(--mdc-theme-secondary);
    // -webkit-text-fill-color: var(--mdc-theme-secondary) !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: var(--mdc-theme-text-primary-on-background) !important;
    -webkit-background-clip: text;
  }
  label {
    @include getFillAvailableWidth();
    div {
      border-color: transparent !important;
      span {
        color: var(--mdc-theme-text-primary-on-background) !important;
      }
    }

    &:not([class*='mdc-text-field--disabled'])[class*='mdc-text-field--with-internal-counter'] {
      div[class*='mdc-text-field-character-counter'] {
        @include getColor(#4da8da, 1);
      }
    }
  }

  &Elevation {
    display: flex;
    background: $fieldBg;
    @include getBorder(rgb(77, 168, 218), 0.4, inset);
    border-radius: $pixelUnit;
    height: fit-content;
    @include getFillAvailableWidth();
    &:not([class*='mdc-elevation--z0']) {
      border-color: rgba(77, 168, 218, 0.2);
    }
    label {
      i {
        color: var(--mdc-theme-secondary) !important;
      }
      &[class*='mdc-text-field--disabled'] {
        i {
          color: inherit;
        }
      }
    }
  }
}
