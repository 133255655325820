@mixin logo-flicker() {
  animation-name: border-flicker, logo-fade-in;
  animation-duration: 20s, 0.3s;
  animation-delay: 1.5s, 1s;
  animation-timing-function: linear, linear;
  animation-iteration-count: infinite, 1;
}

@mixin logo-light-up() {
  animation: logo-light-fade-in 3s ease-in 0.5s 1;
}

@mixin logo-text-appear() {
  animation: logo-fade-in 2s linear 1.2s 1 forwards;
}

@mixin iconPulsate() {
  animation: icon-pulse 5s linear 0.1s infinite forwards;
}

@keyframes icon-pulse {
  0% {
    font-variation-settings: 'FILL' 0, 'wght' 100, 'GRAD' 0, 'opsz' 48;
    color: inherit;
  }
  25% {
    font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 48;
  }
  50% {
    font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 48;
  }
  75% {
    font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48;
    color: var(--mdc-theme-primary);
  }
  100% {
    font-variation-settings: 'FILL' 1, 'wght' 100, 'GRAD' 0, 'opsz' 48;
    color: var(--mdc-theme-primary);
  }
}

@keyframes logo-light-fade-in {
  0% {
    opacity: 0.1;
  }
  31% {
    opacity: 0.3;
  }
  33% {
    opacity: 0.4;
  }
  35% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes logo-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  1% {
    opacity: 1;
  }
  3% {
    opacity: 0.1;
  }
  6% {
    opacity: 1;
  }
  89% {
    opacity: 1;
  }
  90% {
    opacity: 0.7;
  }
  92% {
    opacity: 1;
  }
  97% {
    opacity: 0.9;
  }
  98% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.9;
  }
}

/// Fades in element animation
/// 
/// @param {number} $duration 
///   Animation duration
/// @param {number} $delay
///   Delay animation
/// @param {number} $itertationCount
///   Number of times to run the animation
@mixin fade-in-animation($duration, $delay, $iterationCount) {
  animation-name: fadeIn;
  animation-duration: $duration;
  animation-timing-function: ease;
  animation-delay: $delay;
  animation-fill-mode: forwards;
  animation-iteration-count: $iterationCount;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin expand-from-center($duration, $delay, $iterationCount) {
  animation-name: expandFromCenter;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: forwards;
  animation-iteration-count: $iterationCount;
}

$Y_TOP: 150px;
$Y_BOTTOM: 90%;

@keyframes expandFromCenter {
  0% {
    // background: linear-gradient(to bottom, #12232e $Y_TOP, #007cc7 $Y_TOP, #007cc7 $Y_BOTTOM, #12232e $Y_BOTTOM);
    clip-path: polygon(0px 50%, 100% 50%, 100% 50%, 0% 50%);
  }
  100% {
    // background: linear-gradient(to bottom, #12232e $Y_TOP, #007cc7 $Y_TOP, #007cc7 $Y_BOTTOM, #12232e $Y_BOTTOM);
    // background: linear-gradient(to bottom, #12232e $Y_TOP, #12232e $Y_BOTTOM);
    clip-path: polygon(0% $Y_TOP, 100% $Y_TOP, 100% $Y_BOTTOM, 0 $Y_BOTTOM);
  }
}
